import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import qs from 'query-string';

import getEnvConfig from '../../../utils/config/env';
import { PaginatedResponse } from '../../communities/interfaces/PaginatedResponse';
import {
    VisitorsProps,
    VisitorsRequestProps,
} from '../interfaces/visitors.interface';

export const visitorsApi = createApi({
    reducerPath: 'visitors',
    baseQuery: fetchBaseQuery({
        baseUrl: getEnvConfig().env.API_URL,
        prepareHeaders: headers => {
            const token = localStorage.getItem('accessToken');
            headers.set('Authorization', `Bearer ${token}`);
            return headers;
        },
    }),
    tagTypes: ['Visitors'],

    endpoints: builder => ({
        readVisitors: builder.query<
            PaginatedResponse<VisitorsProps>,
            VisitorsRequestProps
        >({
            query: payload => {
                const queryArgs = qs.stringify(payload.filters, {
                    arrayFormat: 'bracket-separator',
                    skipNull: true,
                });
                return {
                    url: `/shops/${payload.id}/visitors?${queryArgs}`,
                };
            },
            transformResponse: (res: { data: VisitorsProps[] }, meta) => {
                return {
                    items: res.data || [],
                    total:
                        Number(meta.response.headers.get('Items-Count')) || 0,
                };
            },
            providesTags: ['Visitors'],
        }),
    }),
});

export const { useLazyReadVisitorsQuery, endpoints } = visitorsApi;
