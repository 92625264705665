import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import getEnvConfig from '../../../utils/config/env';
import { BannerProps, BannerRequest } from '../interfaces/banners.interface';
import {
    CloudPaymentsProps,
    PaymentSystems,
    PaymentSystemsNames,
} from '../interfaces/paymentSettings.interface';

export const paymentSettingsApi = createApi({
    reducerPath: 'paymentSettings',
    baseQuery: fetchBaseQuery({
        baseUrl: getEnvConfig().env.API_URL,
        prepareHeaders: headers => {
            const token = localStorage?.getItem('accessToken');
            headers.set('Authorization', `Bearer ${token}`);
            return headers;
        },
    }),
    tagTypes: ['PaymentSettings'],

    endpoints: builder => ({
        readPaymentSystems: builder.query<Array<PaymentSystems>, number>({
            query: id => {
                return {
                    url: `/shops/get_payment_systems/${id}`,
                    method: 'GET',
                };
            },
            transformResponse: (res: { data: Array<PaymentSystems> }) =>
                res?.data,
            providesTags: ['PaymentSettings'],
        }),

        updatePaymentSystems: builder.mutation<
            PaymentSystems,
            { id: number; payment_system: PaymentSystemsNames }
        >({
            query: body => {
                return {
                    url: `/shops/set_payment_system/${body.id}`,
                    method: 'POST',
                    body: body,
                };
            },
            transformResponse: (res: { data: PaymentSystems }) => res?.data,
            invalidatesTags: ['PaymentSettings'],
        }),

        updateCloudPayments: builder.mutation<void, CloudPaymentsProps>({
            query: body => ({
                url: '/cloud-payments/keys',
                method: 'POST',
                body: body,
            }),
            invalidatesTags: ['PaymentSettings'],
        }),

        readCloudPayments: builder.query<CloudPaymentsProps, number>({
            query: id => {
                return {
                    url: `/cloud-payments/keys/${id}`,
                    method: 'GET',
                };
            },
            transformResponse: (res: { data: CloudPaymentsProps }) => res?.data,
            providesTags: ['PaymentSettings'],
        }),
    }),
});

export const {
    useLazyReadPaymentSystemsQuery,
    useUpdatePaymentSystemsMutation,
    useLazyReadCloudPaymentsQuery,
    useUpdateCloudPaymentsMutation,
} = paymentSettingsApi;
