import React, {
    useEffect,
    useState,
    Dispatch,
    SetStateAction,
    useMemo,
} from 'react';
import { TreeSelect } from 'antd';
import { useSelector } from 'react-redux';

import { useLazyReadProductCategoryQuery } from '../../services/categories';
import { getCategoryState } from '../../store/categorySlice';

type TreeCategoryFilterProps = {
    shopId: number;
    filters: any;
    setFilters: Dispatch<SetStateAction<any>>;
    width?: number;
    isDisabled?: boolean;
};
export const TreeCategoryFilter: React.FC<TreeCategoryFilterProps> = props => {
    const { shopId, isDisabled = false, filters, setFilters } = props;

    const { nestedCategoryList } = useSelector(getCategoryState);

    const [active, setActive] = useState<number | 'null'>('null');

    const [getCategories, { isLoading: isGetCategoriesLoading }] =
        useLazyReadProductCategoryQuery();
    useEffect(() => {
        if (shopId) {
            getCategories({ shop_id: +shopId });
        }
    }, [shopId]);

    const onChange = (newValueId: number | 'null') => {
        setFilters({
            ...filters,
            category_id: newValueId === 'null' ? null : newValueId,
        });

        setActive(newValueId);
    };

    const dropdownItems = useMemo(() => {
        return nestedCategoryList.map(category => {
            if (category.id === null) {
                return {
                    ...category,
                    id: 'null',
                };
            }
            return category;
        });
    }, [nestedCategoryList]);

    return (
        <TreeSelect
            style={{ width: props?.width || 300 }}
            treeData={dropdownItems}
            fieldNames={{
                label: 'name',
                value: 'id',
                children: 'childCategoryList',
            }}
            onChange={onChange}
            defaultValue={'null'}
            value={active}
            disabled={isDisabled}
        />
    );
};
