import React, { useEffect, useState, useMemo } from 'react';
import { TreeSelect } from 'antd';
import { FormikProps } from 'formik';
import { useSelector } from 'react-redux';

import { useLazyReadProductCategoryQuery } from '../../services/categories';
import { IProducts } from '../../interfaces/products.interface';
import { getCategoryState } from '../../store/categorySlice';

type ProductCategoriesSelectorProps = {
    shopId: number;

    width?: number;
    formik?: FormikProps<IProducts>;
    type?: string;
    isDisabled: boolean;
};
export const ProductCategoriesSelector: React.FC<
    ProductCategoriesSelectorProps
> = props => {
    const { formik, shopId, isDisabled } = props;

    const { nestedCategoryList } = useSelector(getCategoryState);

    const [selectedCategories, setSelectedCategories] = useState<number[]>([0]);

    const [getCategories] = useLazyReadProductCategoryQuery();

    useEffect(() => {
        if (shopId) {
            getCategories({ shop_id: +shopId });
        }
    }, [shopId]);

    useEffect(() => {
        if (formik && nestedCategoryList) {
            setSelectedCategories(formik.values.category_ids);
        }
    }, [nestedCategoryList, formik]);

    const onChange = newValue => {
        if (newValue?.length === 0) {
            setSelectedCategories([0]);
        } else if (newValue?.length >= 2) {
            setSelectedCategories(
                newValue.filter(function (num) {
                    return num !== 0;
                })
            );
        } else {
            setSelectedCategories(newValue);
        }
    };

    const onVisibleChange = isOpen => {
        if (!isOpen) {
            formik.setFieldValue('category_ids', selectedCategories);
        }
    };

    const dropdownItems = useMemo(() => {
        return nestedCategoryList.filter(category => category.id !== null);
    }, [nestedCategoryList, props.type]);

    return (
        <TreeSelect
            bordered={true}
            treeCheckable={true}
            showSearch={false}
            style={{ width: props?.width || 300 }}
            showCheckedStrategy={TreeSelect.SHOW_CHILD}
            onDropdownVisibleChange={onVisibleChange}
            treeData={dropdownItems}
            fieldNames={{
                label: 'name',
                value: 'id',
                children: 'childCategoryList',
            }}
            multiple
            onChange={onChange}
            defaultValue={[0]}
            value={selectedCategories}
            disabled={isDisabled}
        />
    );
};
