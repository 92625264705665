import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { App, Skeleton } from 'antd';

import {
    useReadProductByIdQuery,
    useUpdateProductMutation,
} from '../../services/products';
import { ProductForm } from '../../views/Products/ProductForm';
import { createFormData } from '../../../../utils/common/createFormData';
import { IProducts, ProductsTypes } from '../../interfaces/products.interface';

export const ProductEditContainer = () => {
    const [initial, setInitial] = useState<IProducts>();

    const { notification } = App.useApp();
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const isLinkPage = /\/link-edit\//.test(location.pathname);

    const [
        updateProduct,
        { isSuccess: isSuccessUpdate, isError: isErrorUpdate },
    ] = useUpdateProductMutation();

    const {
        data: product,
        isSuccess,
        isLoading,
    } = useReadProductByIdQuery(Number(id));

    useEffect(() => {
        if (isSuccessUpdate) {
            notification.success({
                message: isLinkPage
                    ? 'Витрина успешно изменена'
                    : 'Товар успешно изменен',
            });
            navigate(-1);
        }
    }, [isSuccessUpdate]);

    useEffect(() => {
        if (isErrorUpdate) {
            notification.error({
                message: 'Произошла ошибка',
            });
        }
    }, [isErrorUpdate]);

    useEffect(() => {
        if (isSuccess) {
            const categoriesIds = product.categories.map(
                category => category.id
            );
            setInitial({
                description: product.description,
                price: product.price,
                old_price: product.old_price,
                title: product.title,
                images: product.images,
                buyable: product.buyable,
                category_ids: categoriesIds,
                status: product?.status,
                link: product?.link,
                type: product?.type,
            });
        }
    }, [isSuccess]);

    const onSave = async (values: IProducts) => {
        const data = {
            title: values.title,
            description: values.description,
            price: values.price,
            old_price: values.old_price,
            images: values.images,
            buyable: values.buyable,
            category_ids: values.category_ids,
            status: values.status,
            link: values.link,
        };

        if (!values.description) {
            delete data.description;
        }

        if (!values.old_price) {
            delete data.old_price;
        }

        if (!isLinkPage) {
            delete data.link;
        }

        if (isLinkPage && !values.link) {
            return notification.error({
                message: 'Нужно указать ссылку на товар',
            });
        }

        if (isLinkPage && !values.price) {
            data.price = 0;
        }

        if (values.old_price && values.price > Number(values.old_price)) {
            return notification.error({
                message: `Цена без скидки не может быть меньше ${values.price}`,
            });
        }

        await updateProduct({ id: Number(id), body: createFormData(data) });
    };

    const isDisableEditProduct = useMemo(() => {
        return product?.external_code !== null && !isLinkPage;
    }, [isLinkPage, product]);

    return isLoading ? (
        <Skeleton />
    ) : (
        initial && (
            <ProductForm
                shopId={product?.shop_id}
                initialValues={initial}
                onSave={onSave}
                isDisableEditProduct={isDisableEditProduct}
            />
        )
    );
};
