import React, { useEffect, useState } from 'react';
import ImgCrop from 'antd-img-crop';
import { App, Button, Col, Flex, Popover, Radio, Upload } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';

import {
    useCreateProductImageMutation,
    useDeleteProductImageMutation,
    useUpdateProductImageCoverMutation,
} from '../../../../shops/services/products';
import { getSrc } from '../../../../../utils/common/getSrc';
import { ProductImage } from '../../../../shops/components/Product/ProductImage';
import { createFormData } from '../../../../../utils/common/createFormData';
import { useToggle } from '../../../../../hooks/useToggle';
import { GenericModal } from '../../../../../shared/ui/GenericModal';

export const ProductUploader = props => {
    const { id } = useParams();
    const { notification } = App.useApp();

    const { formik, type, isEdit, isDisabled } = props;
    const { open, setOpen } = useToggle();
    const [file, setFile] = useState(null);
    const [isUpdate, setIsUpdate] = useState(false);
    const [cover, setCover] = useState(null);

    const [
        createImage,
        {
            isSuccess: isSuccessCreate,
            isLoading: isLoadingCreate,
            data: createData,
        },
    ] = useCreateProductImageMutation();
    const [
        deleteImage,
        {
            isSuccess: isSuccessDelete,
            isLoading: isLoadingDelete,
            data: deleteData,
        },
    ] = useDeleteProductImageMutation();
    const [selectCover, { isSuccess: isSuccessSelectCover, data: coverData }] =
        useUpdateProductImageCoverMutation();

    const dummyRequest = async onSuccess => {
        setTimeout(() => {
            onSuccess('ok');
        }, 0);
    };

    useEffect(() => {
        if (isSuccessDelete) {
            formik.setFieldValue(type, deleteData?.data?.images);
            setFile(null);
            notification.success({
                message: 'Фото успешно удалено',
            });
            props.setLoadingDeleteImage(false);
        }
    }, [isSuccessDelete]);

    useEffect(() => {
        if (isSuccessCreate) {
            formik.setFieldValue(type, createData?.data?.images);
            setFile(null);
            notification.success({
                message: 'Фото успешно добавлено',
            });
            setIsUpdate(false);
        }
    }, [isSuccessCreate]);

    useEffect(() => {
        if (isSuccessSelectCover) {
            formik.setFieldValue(type, coverData?.data?.images);
            notification.success({
                message: 'Обложка успешна установлена',
            });
            setOpen(false);
        }
    }, [isSuccessSelectCover]);

    useEffect(() => {
        if (file && !isEdit)
            formik.setFieldValue(type, [...formik.values.images, file]);
    }, [file]);

    useEffect(() => {
        if (isUpdate && isEdit) {
            const addImage = async () => {
                const data = {
                    image: file,
                };

                await createImage({
                    id: Number(id),
                    body: createFormData(data),
                });
            };

            void addImage();
        }
    }, [isUpdate, isEdit]);

    const onRemove = async (
        value: { uid: string },
        imageId?: number
    ): Promise<void> => {
        props.setLoadingDeleteImage(true);

        if (isEdit) {
            await deleteImage({ id: Number(id), imageId: imageId });
        } else {
            const filteredImages = formik.values.images.filter(
                elem => elem.uid !== value.uid
            );
            formik.setFieldValue(props.type, filteredImages);
        }
        props.setLoadingDeleteImage(false);
    };

    const PopoverContent = (props: { imageId: number }) => {
        const { imageId } = props;
        return <DeleteOutlined onClick={() => onRemove(null, imageId)} />;
    };

    return (
        <div style={{ margin: '10px 0 24px 0' }}>
            <Flex wrap={'wrap'} gap={'small'}>
                {isEdit &&
                    formik?.values?.images?.map(image => (
                        <Col xs={24} md={2} key={image.id}>
                            <Popover
                                style={{ color: 'darkred' }}
                                destroyTooltipOnHide
                                arrow={false}
                                placement={'bottom'}
                                content={
                                    isDisabled ? null : (
                                        <PopoverContent imageId={image.id} />
                                    )
                                }
                            >
                                <div>
                                    <ProductImage
                                        gutter={0}
                                        src={getSrc(`/storage/${image.file}`)}
                                    />
                                </div>
                            </Popover>
                        </Col>
                    ))}

                {isEdit && (
                    <div style={{ minWidth: '100%' }}>
                        <Button
                            disabled={isDisabled}
                            onClick={() => setOpen(true)}
                        >
                            Выбрать обложку
                        </Button>
                    </div>
                )}
                <GenericModal
                    width={400}
                    title={'Выбрать обложку'}
                    open={open}
                    onCancel={() => setOpen(false)}
                    okText={'Сохранить'}
                    onConfirm={() =>
                        selectCover({ id: Number(id), imageId: cover })
                    }
                >
                    <Flex wrap='wrap' gap={8} justify={'space-between'}>
                        {formik?.values?.images?.map((image, index) => (
                            <Col
                                span={7}
                                key={index}
                                onClick={() => setCover(image.id)}
                            >
                                <ProductImage
                                    gutter={0}
                                    src={getSrc(`/storage/${image.file}`)}
                                />
                                <Radio
                                    style={{ marginTop: '.5rem' }}
                                    checked={cover === image.id}
                                    onChange={() => setCover(image.id)}
                                />
                            </Col>
                        ))}
                    </Flex>
                </GenericModal>

                <div style={{ width: 'auto' }}>
                    <ImgCrop
                        rotationSlider
                        quality={0.75}
                        showReset
                        showGrid
                        modalTitle={'Отредактируйте изображение'}
                        modalOk={'Применить'}
                        resetText={'Отменить изменения'}
                    >
                        <Upload
                            disabled={
                                isLoadingCreate ||
                                isLoadingDelete ||
                                formik?.values?.images?.length >= 3 ||
                                isDisabled
                            }
                            customRequest={file => {
                                void dummyRequest(file.onSuccess);
                            }}
                            beforeUpload={file => {
                                setFile(file);
                                setIsUpdate(true);
                            }}
                            listType='picture-card'
                            // showUploadList={!isEdit}
                            onRemove={onRemove}
                            showUploadList={
                                isEdit
                                    ? false
                                    : {
                                          showPreviewIcon: false,
                                      }
                            }
                            onPreview={() => {}}
                        >
                            <div>
                                <PlusOutlined />
                                <div style={{ marginTop: 8 }}>Фото товара</div>
                            </div>
                        </Upload>
                    </ImgCrop>
                </div>
            </Flex>
        </div>
    );
};
