import React, { useEffect } from 'react';
import { App, Button, Space, Typography } from 'antd';

import { useUpdateSubscriptionMutation } from '../../services';
import { useProfileData } from '../../../../hooks/useProfileData';
import { GenericModal } from '../../../../shared/ui/GenericModal';
import { useToggle } from '../../../../hooks/useToggle';

export const Recurrent = props => {
    const { isRecurrent, expirationDate } = props;
    const { notification } = App.useApp();
    const { refetch } = useProfileData();
    const { open, setOpen } = useToggle();

    const [updateSubscription, { isLoading, isSuccess }] =
        useUpdateSubscriptionMutation();

    useEffect(() => {
        if (isSuccess) {
            refetch();
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isSuccess && !isRecurrent) {
            return notification.success({
                message: 'Автопродление подключено',
                description:
                    'Необходимая сумма будет автоматически списываться с указанного ранее вами счёта/карты при оплате подписки. ',
            });
        }
    }, [isSuccess]);

    const handleUpdateSubscription = () => {
        if (isRecurrent) {
            setOpen(true);
        } else
            updateSubscription({})
                .unwrap()
                .finally(() => setOpen(false));
    };

    return (
        <Space direction='vertical' align='end'>
            <Button
                size={'small'}
                loading={isLoading}
                disabled={isLoading}
                type={isRecurrent ? 'link' : 'primary'}
                onClick={() => {
                    handleUpdateSubscription();
                }}
            >
                {isRecurrent ? 'Отключить' : 'Подключить автопродление'}
            </Button>

            <GenericModal
                title={'Отключить'}
                destroyOnClose
                width={360}
                open={open}
                okText={'Отключить'}
                onCancel={() => {
                    setOpen(false);
                }}
                onConfirm={() =>
                    updateSubscription({})
                        .unwrap()
                        .finally(() => setOpen(false))
                }
                okButtonProps={{
                    danger: true,
                }}
            >
                <Space direction={'vertical'} size={'large'}>
                    <Typography.Text>
                        Вы действительно хотите отключить подписку?
                    </Typography.Text>
                    <Typography.Text>
                        После {expirationDate} все платные функции станут
                        недоступны.
                    </Typography.Text>
                </Space>
            </GenericModal>
        </Space>
    );
};
