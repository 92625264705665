import { Field, Form, Formik, useFormik } from 'formik';
import React, { useEffect } from 'react';
import { App, Card, Checkbox, Flex, Input } from 'antd';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';

import { ShopValues } from '../../../interfaces/shop.interface';
import { createFormData } from '../../../../../utils/common/createFormData';
import {
    useReadShopByIdQuery,
    useUpdateShopMutation,
} from '../../../services/shops';
import { getShopState } from '../../../store/shopSlice';
import { useTheme } from '../../../../../hooks/useTheme';

export const SwithByableShop = () => {
    const { id } = useParams();
    const token = useTheme();
    const { notification } = App.useApp();
    const [updateShop, { isSuccess, isLoading, isError, error }] =
        useUpdateShopMutation();

    const { data: shop, isLoading: isQueryLoading } = useReadShopByIdQuery(+id);

    const onSave = async (values: ShopValues) => {
        const data = {
            id: +id,
            buyable: values.buyable,
        };

        await updateShop({ id: +id, body: createFormData(data) });
    };

    const shopInfo = (({ name, buyable }) => ({
        name,
        buyable,
    }))(useSelector(getShopState));

    const handleChange = event => {
        const data = {
            id: +id,
            name: shopInfo.name,
            buyable: !!event.target.checked,
        };

        // updateShop({ id: +id, body: data });
    };

    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: 'Магазин успешно изменен',
            });
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError) {
            return notification.error({
                message:
                    error?.data?.message ||
                    'Произошла ошибка, попробуйте позже',
            });
        }
    }, [isError]);

    return (
        <Formik
            initialValues={shopInfo}
            enableReinitialize={true}
            onSubmit={onSave}
        >
            <Form>
                <Flex vertical gap={'1.5rem'}>
                    <div>
                        <Field name='buyable'>
                            {({ field, form, meta }) => (
                                <div>
                                    <Checkbox
                                        {...field}
                                        checked={field.value}
                                        disabled
                                        // onChange={e => handleChange(e)}
                                    >
                                        Оплата товаров через магазин{' '}
                                        {isLoading && <LoadingOutlined />}
                                    </Checkbox>
                                </div>
                            )}
                        </Field>
                    </div>
                </Flex>
            </Form>
        </Formik>
    );
};
