import React, { useEffect, useState } from 'react';
import { Card, Flex, GlobalToken, Typography } from 'antd';
import styled from 'styled-components';

import { useTheme } from 'hooks/useTheme';

import { format } from '../../../../lib/date';
import { Recurrent } from '../Recurrent';
import { useProfileData } from '../../../../hooks/useProfileData';
import { SubscriptionProps } from '../../interfaces';
import { Benefits } from '../Benefits';

export const SubscriptionCard = (props: SubscriptionProps) => {
    const { name, price, description } = props;
    const token = useTheme();
    const { user } = useProfileData();

    const [expirationDate, setExpirationDate] = useState<string>();

    useEffect(() => {
        if (user) {
            setExpirationDate(
                format(user.subscription?.expiration_date)('DD.MM.YYYY')
            );
        }
    }, [user]);

    const isRecurrent = user?.subscription.isRecurrent;

    return (
        <StyledCard
            loading={!user}
            token={token}
            type='inner'
            style={token.Card}
        >
            <Flex vertical gap={32}>
                <Flex justify={'space-between'} wrap={'wrap'} gap={24}>
                    <Flex vertical gap={4}>
                        <StyledTitle level={5}>Пакет услуг</StyledTitle>
                        <StyledTitle level={3}>{name}</StyledTitle>
                    </Flex>

                    <Flex vertical gap={8}>
                        {isRecurrent ? (
                            <StyledExplanation gap={8} align={'flex-end'}>
                                <Flex vertical={false} gap={8}>
                                    <Typography.Text>
                                        Автопродление
                                    </Typography.Text>
                                    <Typography.Text strong={true}>
                                        {expirationDate}
                                    </Typography.Text>
                                </Flex>
                            </StyledExplanation>
                        ) : (
                            <StyledExplanation gap={8} align={'flex-end'}>
                                <Typography.Text>Действует до</Typography.Text>

                                <Typography.Text strong={true}>
                                    {expirationDate}
                                </Typography.Text>
                            </StyledExplanation>
                        )}

                        <Recurrent
                            isRecurrent={isRecurrent}
                            expirationDate={expirationDate}
                        />
                    </Flex>
                </Flex>

                <StyledExplanation
                    gap={24}
                    justify={'space-between'}
                    align={'flex-end'}
                >
                    <Flex vertical gap={8}>
                        <Benefits source={description} />
                    </Flex>

                    <Flex justify={'flex-end'}>
                        <StyledPrice>{price}₽ в месяц</StyledPrice>
                    </Flex>
                </StyledExplanation>
            </Flex>
        </StyledCard>
    );
};

const StyledExplanation = styled(Flex)`
    display: flex;
    flex-direction: row;

    @media (max-width: 576px) {
        flex-direction: column;
        align-items: stretch;
    }
`;

const StyledCard = styled(Card)<{
    $active?: boolean;
    token: GlobalToken;
    style?: any;
}>`
    flex: 1;
    width: 100%;
`;

const StyledPrice = styled.div`
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: rgba(37, 33, 41, 0.88);
`;

const StyledTitle = styled(Typography.Title)`
    margin: 0 !important;
`;
