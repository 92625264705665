import React from 'react';
import styled from 'styled-components';
import { Outlet } from 'react-router-dom';

import { Container } from 'shared/ui/Container';
import ErrorBoundary from 'shared/ui/ErrorBoundary';

import { PayModal } from '../../../features/subscriptions/components/PayModal';

export const PayLayout: React.FC = props => {
    return (
        <StyledLayout>
            <StyledContent>
                <ErrorBoundary>
                    <Outlet />
                    {/*<PayModal />*/}
                </ErrorBoundary>
            </StyledContent>
        </StyledLayout>
    );
};

const StyledLayout = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: rgb(244, 240, 255);
    overflow-y: scroll;
`;

const StyledContent = styled(Container)`
    flex: 1;
    margin-top: calc(1.5rem + 1.5vw);
    margin-bottom: 48px;
`;
