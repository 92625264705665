import React, { useEffect, useState } from 'react';
import { App, Button, Flex, Input, Typography } from 'antd';
import { Field, Formik, Form } from 'formik';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import {
    useLazyReadCloudPaymentsQuery,
    useUpdateCloudPaymentsMutation,
} from 'features/shops/services/paymentSettings';

import { CloudPaymentsFormProps } from '../../../../interfaces/paymentSettings.interface';

export const Step_2 = () => {
    const { id } = useParams();
    const { notification } = App.useApp();

    const [initialValues, setInitialValues] = useState<CloudPaymentsFormProps>({
        publicId: '',
        apiSecret: '',
    });

    const [
        createCloudPayments,
        { isSuccess, isLoading: isLoadingCreate, isError, error },
    ] = useUpdateCloudPaymentsMutation();
    const [getCloudPayments, { isSuccess: isSuccessRead, data }] =
        useLazyReadCloudPaymentsQuery();

    useEffect(() => {
        if (id) {
            getCloudPayments(+id, true);
        }
    }, [id]);

    useEffect(() => {
        if (isSuccessRead && data) {
            setInitialValues({ ...data });
        }
    }, [isSuccessRead, data]);

    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: 'Успешно сохранено',
                placement: 'top',
            });
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError) {
            notification.error({
                message: error?.data.message || 'Произошла ошибка',
                placement: 'top',
            });
        }
    }, [isError]);

    const onSave = async values => {
        const data = {
            shop_id: +id,
            publicId: values.publicId,
            apiSecret: values.apiSecret,
        };

        await createCloudPayments({ ...data });
    };

    const onValidate = values => {
        const errors = {};
        if (values.publicId === '') {
            errors.publicId = 'Укажите ID';
        }
        if (values.apiSecret === '') {
            errors.apiSecret = 'Введите ключ';
        }
        return errors;
    };

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validate={onValidate}
            onSubmit={onSave}
        >
            <Form>
                <Typography.Text>Шаг 2. Предоставление доступа</Typography.Text>

                <Flex vertical gap={16}>
                    <Field name='publicId'>
                        {({ field, form, meta }) => (
                            <div>
                                <StyledLabel>
                                    Скопируйте Public ID из настроек, вставьте в
                                    поле ниже
                                </StyledLabel>
                                <Input
                                    status={meta.error ? 'error' : ''}
                                    style={{ maxWidth: '284px' }}
                                    {...field}
                                />
                            </div>
                        )}
                    </Field>
                    <Field name='apiSecret'>
                        {({ field, form, meta }) => (
                            <div>
                                <StyledLabel>
                                    Добавьте пароль для API и нажмите
                                    “Сохранить”
                                </StyledLabel>
                                <Input.Password
                                    status={meta.error ? 'error' : ''}
                                    style={{ maxWidth: '284px' }}
                                    {...field}
                                />
                            </div>
                        )}
                    </Field>
                    <Flex>
                        <Button
                            disabled={isLoadingCreate}
                            type='primary'
                            htmlType='submit'
                        >
                            Сохранить
                        </Button>
                    </Flex>
                </Flex>
            </Form>
        </Formik>
    );
};

const StyledLabel = styled.label`
    display: block;
    margin-bottom: 8px;
`;
