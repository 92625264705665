import React, {
    createElement,
    Fragment,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { Flex } from 'antd';

import { notification } from 'shared/ui/GlobalNotification';

import {
    useLazyReadPaymentSystemsQuery,
    useUpdatePaymentSystemsMutation,
} from '../../services/paymentSettings';
import {
    PaymentSystems,
    PaymentSystemsNames,
} from '../../interfaces/paymentSettings.interface';
import { Page } from '../../../../shared/ui/Page';
import { PaymentSystemCard } from '../../views/Payment/PaymentSystemCard';
import { cloudPaymentsSteps } from '../../components/Payment/cloudPayments';
import { SwithByableShop } from '../../components/Payment/SwithByableShop';
import Buyable from '../../components/Buyables';

export const PaymentSettingsContainer = () => {
    const { id } = useParams();
    const [toggledSystem, setToggledSystem] =
        useState<PaymentSystemsNames>(null);

    const [updateActivePaymentSystem, { isSuccess }] =
        useUpdatePaymentSystemsMutation();

    const [getPaymentSystems, { data, isLoading }] =
        useLazyReadPaymentSystemsQuery();

    useEffect(() => {
        if (id) {
            getPaymentSystems(+id, false);
        }
    }, [id]);

    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: 'Успешно изменена система оплаты',
                placement: 'top',
            });
        }
    }, [isSuccess]);

    const updatePaymentSystem = useCallback(async (param: PaymentSystems) => {
        if (param.active) {
            return notification.error({
                duration: 1,
                message: 'Уже выбрана в качестве основной',
            });
        }

        if (!param.connect) {
            return notification.success({
                duration: 1,
                message:
                    'Нельзя выбрать в качестве основной, пока полностью не настроена',
            });
        }

        const payload = {
            payment_system: param.name,
            id: +id,
        };

        await updateActivePaymentSystem(payload);
    }, []);

    const cloudPayment = useMemo(() => {
        if (data)
            return data.find(
                paymentSystem =>
                    paymentSystem.name === PaymentSystemsNames.cloudPayments
            );

        return {};
    }, [data]);

    // const StepsRenders = {
    //     [PaymentSystemsNames.cloudPayments]: cloudPaymentsSteps,
    // };

    return (
        <Page title='Выбор платежной системы'>
            <Flex
                vertical
                justify={'space-between'}
                wrap={'wrap'}
                gap={20}
                style={{ paddingTop: '1.5rem' }}
            >
                <Buyable />
                <SwithByableShop />

                {/*{data?.map(paymentSystem => (*/}
                {/*    <PaymentSystemCard*/}
                {/*        key={paymentSystem.name}*/}
                {/*        updatePaymentSystem={updatePaymentSystem}*/}
                {/*        toggle={setToggledSystem}*/}
                {/*        isToggled={toggledSystem === paymentSystem.name}*/}
                {/*        {...paymentSystem}*/}
                {/*    >*/}
                {/*        {StepsRenders[paymentSystem.name]?.map(paymentStep => (*/}
                {/*            <>{paymentStep && createElement(paymentStep, {})}</>*/}
                {/*        ))}*/}
                {/*    </PaymentSystemCard>*/}
                {/*))}*/}

                <PaymentSystemCard
                    updatePaymentSystem={updatePaymentSystem}
                    toggle={setToggledSystem}
                    isToggled={
                        toggledSystem === PaymentSystemsNames.cloudPayments
                    }
                    loading={isLoading}
                    {...cloudPayment}
                >
                    {cloudPaymentsSteps?.map((paymentStep, index) => (
                        <Fragment key={index}>
                            {paymentStep && createElement(paymentStep, {})}
                        </Fragment>
                    ))}
                </PaymentSystemCard>
            </Flex>
        </Page>
    );
};
