import React, { useEffect, useState } from 'react';
import { Flex, Typography } from 'antd';
import styled from 'styled-components';

export const Benefits = props => {
    const { source } = props;
    const [benefits, setBenefits] = useState([]);

    useEffect(() => {
        if (source) {
            setBenefits(JSON.parse(source));
        }
    }, [source]);

    return (
        <StyledUl>
            {benefits?.map((benefit, index) => (
                <StyledLi key={index}>
                    <Typography.Text>{benefit.name}</Typography.Text>
                </StyledLi>
            ))}
        </StyledUl>
    );
};

const StyledUl = styled.ul`
    margin: 0 !important;
    padding-left: 16px;

    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const StyledLi = styled.li`
    list-style: disc;
    padding-left: 4px;
`;

/*[{"name": "Создайте в своем магазине до 50 товаров","description" : null},{"name": "Модули модерация, платный доступ, донаты включены в тариф ограничение до 3-х чатов", "description": null},
{"name": "Размещайте данные объёмом трафика для хранения медиа - 10 ГБ и 10 постов в мес.","description": null},
{ "name" : "Комиссия при выводе денежных средств: ", "description": "7%" } ]*/
